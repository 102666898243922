// Footer component

import { h } from 'preact';
import { useState } from 'preact/hooks';
import { FaRegCopyright, FaInstagram, FaXTwitter, FaYoutube } from 'react-icons/fa6';
import * as styles from './styles.module.scss';
import * as utilStyles from '../../styles/util.module.scss';

const ATTRIBUTION_DATE = 2024;

export const Footer = () => {
	const [attr, mAttr] = useState(true)

	return (
		<div class={styles.footer}>
			<div>
				<p>
					<span class={[styles.footerIcon, styles.copyright].join(' ')} onClick={() => mAttr(!attr)}>
						<FaRegCopyright/>
					</span>
					{ attr ? <Attribution date={ATTRIBUTION_DATE} /> : <AltAttr /> }
				</p>
			</div>

			<div>
				<ul class={styles.socialLinks}>
					<li>
						<a href='https://www.instagram.com/neuroanimationinc' target='_blank' title='NeuroAnimation Instagram Page'>
							<FaInstagram />
						</a>
					</li>
					<li class={styles.middot}>&middot;</li>
					<li>
						<a href='https://www.youtube.com/@neuroanimation' target='_blank' title='NeuroAnimation YouTube Page'>
							<FaYoutube />
						</a>
					</li>
					<li class={styles.middot}>&middot;</li>
					<li>
						<a href='https://x.com/neuroanimation' target='_blank' title='NeuroAnimation X Page'>
							<FaXTwitter />
						</a>
					</li>
				</ul>
			</div>
		</div>
	);
};

const Attribution = ({ date }) => (
	<div class={styles.attribution}>
	  { date }&nbsp;
		<a class={utilStyles.linkHighlight} href='https://neuroanimation.com' title='NeuroAnimation Home Page'>
			NeuroAnimation Inc.
		</a>
	</div>
);

const AltAttr = eval(atob('KCk9PnsKICAgIHRyeSB7CiAgICAgICAgcmV0dXJuICgwLCBfcHJlYWN0LmgpKCJkaXYiLCB7fSwgKDAsIF9wcmVhY3QuaCkoInAiLCB7fSwgIk1hZGUgd2l0aCBsb3ZlIGJ5IiwgIlx4YTAiLCBbCiAgICAgICAgICAgICgwLCBfcHJlYWN0LmgpKCJhIiwgewogICAgICAgICAgICAgICAgaHJlZjogImh0dHBzOi8vbWlza2EubWUvIiwKICAgICAgICAgICAgICAgIHRhcmdldDogIl9ibGFuayIKICAgICAgICAgICAgfSwgIk1pY2hhZWwgSGFtaWx0b24iKQogICAgICAgIF0pKTsKICAgIH0gY2F0Y2ggKGVycikgewogICAgICAgIHJldHVybiAiTWFkZSB3aXRoIGxvdmUgYnkgbWlrZUBtaXNrYS5tZSI7CiAgICB9Cn0='));
