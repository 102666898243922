@import '../../styles/variables';

.footer {
	align-items: center;
	background: $darker-gray url('../../static/images/section-texture.png.webp') repeat;
	display: flex;
	box-shadow: 0 0 2rem $darker-gray;
	justify-content: space-between;
	padding: 2rem;
	z-index: 999;

	& > * {
		flex: 1;

		@media screen and (max-width: $break-small) {
			flex: none;
		}
	}

	.footerIcon {
		display: flex;

		&.copyright {
			color: $white;
			margin-right: 0.5rem;

			&:hover {
				cursor: pointer;
			}
		}
	}

	.attribution {
		user-select: none;
	}

	p {
		align-items: center;
		color: $white;
		display: inline-flex;
		font-size: 1.8rem;
		margin-bottom: 0;
		justify-content: center;
		transition: 0.125s font-size;

		@media screen and (max-width: $break-med) {
			font-size: 1.6rem;
		}

		@media screen and (max-width: $break-small) {
			font-size: 1.2rem;
		}
	}

	a {
		color: $light-gray;
		font-weight: 300;
		font-size: 1.8rem;
		transition: 0.125s color, font-size;

		&:hover {
			color: darken($light-gray, 25%);
			transition: color 0.125s;
		}

		@media screen and (max-width: $break-med) {
			font-size: 1.6rem;
		}

		@media screen and (max-width: $break-small) {
			font-size: 1.2rem;
		}
	}

	.footerLogo {
		align-items: center;
		display: flex;
		justify-content: center;
		user-select: none;

		&:hover {
			cursor: pointer;
		}

		img {
			height: 4rem;
		}

		@media screen and (max-width: $break-small) {
			display: none;
		}
	}

	.footerLinks {
		align-items: center;
		display: flex;
		justify-content: center;
		user-select: none;

		li {
			align-items: center;
			display: flex;
			line-height: 3.6rem;
			font-size: 2.4rem;
			padding: 0 0.5rem;
			transition: 0.125s font-size;

			a {
				align-items: center;
				display: inline-flex;
			}

			&.middot {
				color: $light-gray;
			}

			@media screen and (max-width: $break-med) {
				font-size: 1.6rem;
			}

			@media screen and (max-width: $break-small) {
				font-size: 1.2rem;
			}
		}
	}

	.socialLinks {
		align-items: center;
		display: flex;
		justify-content: flex-end;
		user-select: none;

		li {
			align-items: center;
			display: flex;
			line-height: 3.6rem;
			font-size: 2.4rem;
			padding: 0 0.5rem;
			transition: 0.125s font-size;

			a {
				align-items: center;
				display: inline-flex;
			}

			&.middot {
				color: $light-gray;
			}

			@media screen and (max-width: $break-med) {
				font-size: 1.6rem;
			}

			@media screen and (max-width: $break-small) {
				font-size: 1.2rem;
			}
		}
	}
}
