// Misc utilities

import { Page } from '../pages/pages.ts';

export function convertPagesToNavItems(parent: Page): Page[] {
  return Object.keys(parent).filter(page => {
    return parent[page].showInMainNav;
  }).map(page => {
    let children: Page[];

    if (parent[page].children) {
      children = convertPagesToNavItems(parent[page].children);
    }

    return {
      ...parent[page],
      children
    };
  });
}
