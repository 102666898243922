// Site entrypoint

import { useEffect, useRef, useState } from 'preact/hooks';
import Router from 'preact-router';
import AsyncRoute from 'preact-async-route';
import { SectionLoader } from './components/Loaders';
import { Footer } from './components/Footer';
import Modal from './components/Modal';
import NavLogo from './components/NavLogo';
import { Navigation } from './components/Navigation';
import useModal from './hooks/useModal.js';
import { convertPagesToNavItems } from './lib/utils.ts';
import Pages, { Page } from './pages/pages.ts';
import * as layoutStyles from './styles/layout.module.scss';

const navItems = convertPagesToNavItems(Pages);

const Site = () => {
	const { isActive } = useModal();

	const [headerHeightClass, setHeaderHeightClass] = useState("full");
	const [showHeader, setShowHeader] = useState(true);

	const mainNav = useRef();
	const navigation = useRef();

	const updateDocument = (page: Page) => {
		document.title = page.documentTitle;
		document.querySelector('meta[name="description"]').setAttribute("content", page.description)
	}

	// This is an anti-pattern in React, oh well
	useEffect(() => {
		if(isActive) {
			document.body.classList.add('no-scroll');
		}
		else {
			document.body.classList.remove('no-scroll');
		}
	}, [isActive]);

	const [hasLoaded, setHasLoaded] = useState(false);
	const loaderDelayMs = 500;

	const scroll = () => {
		setTimeout(() => {
			const mainContentWrapper = document.getElementById('main-content-wrapper');
			window.scrollTo({top: mainContentWrapper.getBoundingClientRect().top + window.scrollY -20, behavior: 'smooth'});
		}, 10);
	};

	// Handle special cases on route change events
	const handleRouteChange = (e) => {
		window.scrollTo({ top: 0 });

		try {
			if (e.active[0].props.default) {
				setHasLoaded(true);
			}
			else {
				setHasLoaded(false);
			}
		}
		catch(err) {
			setHasLoaded(true);
		}
	};

	return (
		<>
			<Modal />

			<div class={layoutStyles.siteWrapper}>
				<div id={'main-content-wrapper'} class={[layoutStyles.mainContentWrapper, hasLoaded ? layoutStyles.hasLoaded : null].join(' ')}>
					{/*<nav ref={mainNav} class={layoutStyles.mainNav}>*/}
					{/*	<NavLogo />*/}
					{/*	<Navigation ref={navigation} navItems={navItems} />*/}
					{/*</nav>*/}

					<Router onChange={handleRouteChange}>
						{
							Object.keys(Pages).map(page =>
								<AsyncRoute
									path={Pages[page].href}
									getComponent={ () => {
										setHeaderHeightClass('full');
										updateDocument(Pages[page]);
										setShowHeader(Pages[page].showHeader);

										return Promise.all([
											Pages[page]?.srcImport(),
											new Promise(resolve => setTimeout(resolve, loaderDelayMs))
										]).then(([module]) => {
											setHasLoaded(true);
											return module.default;
										});
									}}
									loading={ () => <SectionLoader /> }
								/>
							)
						}

						<AsyncRoute
							path='*'
							default
							getComponent={ () => {
								setHeaderHeightClass('full');
								setShowHeader(false);

								return Promise.all([
									import('./pages/notfound/index.js'),
									new Promise(resolve => setTimeout(resolve, loaderDelayMs))
								]).then(([module]) => {
									setHasLoaded(true);
									return module.default;
								});
							}}
							loading={ () => <SectionLoader /> }
						/>
					</Router>

					<Footer />
				</div>
			</div>
		</>
	);
}

export default Site;
